import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import * as VueGoogleMaps from "vue2-google-maps";
import GmapCluster from "vue2-google-maps/dist/components/cluster";
import VueMeta from "vue-meta";
import * as Sentry from "@sentry/vue";

// FirebaseApp
import { initializeApp } from "firebase/app";
import {
  getFirestore,
  doc,
  setDoc,
  addDoc,
  collection,
  collectionGroup,
  increment,
  where,
  query,
  getDocs,
  getDoc,
  deleteDoc,
  updateDoc,
  arrayUnion,
  arrayRemove,
} from "firebase/firestore";
import { getAnalytics } from "firebase/analytics";
import {
  getAuth,
  deleteUser,
  signOut,
  getRedirectResult,
  getAdditionalUserInfo,
  updateEmail,
  EmailAuthProvider,
} from "firebase/auth";
import "firebase/functions";
import { getDatabase, ref, set } from "firebase/database";
import { getStorage, uploadBytes } from "firebase/storage";
import { getFunctions, httpsCallable } from "firebase/functions";
import config from "./configs/config-loop";

// import { connectFunctionsEmulator } from "firebase/functions";

const firebaseConfig = {
  apiKey: config.firebaseConfig.apiKey,
  authDomain: config.firebaseConfig.authDomain,
  projectId: config.firebaseConfig.projectId,
  storageBucket: config.firebaseConfig.storageBucket,
  databaseURL: config.firebaseConfig.databaseURL,
  messagingSenderId: config.firebaseConfig.messagingSenderId,
  appId: config.firebaseConfig.appId,
  measurementId: config.firebaseConfig.measurementId,
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const database = getDatabase(app);
const storage = getStorage(app);
const db = getFirestore(app);
const auth = getAuth(app);
// const message = getMessaging(app);
// const functions = getFunctions(app);

// connectFunctionsEmulator(functions, "localhost", 5001);

Vue.config.productionTip = false;

function loadStylesheet() {
  const link = document.createElement("link");
  link.rel = "stylesheet";
  link.href = config.branding.styleSheet;
  document.head.appendChild(link);
}

function loadGoogleFonts() {
  const { googleFonts } = config.branding;
  const link = document.createElement("link");
  link.rel = "stylesheet";
  link.href = `https://fonts.googleapis.com/css?family=${googleFonts.fontFamilies.join(
    "|"
  )}&display=swap`;
  document.head.appendChild(link);
}

console.log(config.branding.appName);

if (config.branding.appName === "TentShare") {
  console.log("this show fire");
  loadGoogleFonts();
  loadStylesheet();
}

Vue.use(VueMeta);

Vue.use(VueGoogleMaps, {
  load: {
    key: "AIzaSyDg0qe-o5o6Nz4i3sMeLhyjXxisJd1CBiI",
    libraries: "places, geometry",
    installComponents: true,
  },
});

Vue.component("GmapCluster", GmapCluster);

Vue.prototype.$sortAlphabetically = (array: any[], type: string) => {
  const items = array.slice().sort((a: any, b: any) => {
    return type === "booking"
      ? a.item_name.localeCompare(b.item_name)
      : a.name.localeCompare(b.name);
  });

  return items;
};

Vue.prototype.$orderBy = function (array: any[], key: string, type: string) {
  const sortedArray = array.slice();

  // Sort the array based on the "start" date
  sortedArray.sort((a: any, b: any) => {
    let dateA;
    let dateB;
    if (type === "item") {
      dateA = a.created_unix;
      dateB = b.created_unix;
    } else {
      dateA = Number(new Date(a.start));
      dateB = Number(new Date(b.start));
    }
    // Use conditional operators to switch between ascending and descending order
    return key === "asc" ? dateA - dateB : dateB - dateA;
  });

  return sortedArray;
};

Vue.prototype.$sortByPrice = function (array: any[], key: string) {
  const sortedArray = array.slice();

  // Sort the array based on the "start" date
  sortedArray.sort((a: any, b: any) => {
    const priceA = Number(a.price);
    const priceB = Number(b.price);
    // Use conditional operators to switch between ascending and descending order
    return key === "asc" ? priceA - priceB : priceB - priceA;
  });
  return sortedArray;
};

Vue.prototype.$imageShuffle = function () {
  const images = [
    "loop_header_1.png",
    "loop_header_2.png",
    "loop_header_3.png",
    "loop_header_4.png",
    "loop_header_5.png",
  ];

  const shuffleArray = (array: string[]) => {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
  };

  shuffleArray(images);
  return images[0];
};

if (process.env.NODE_ENV === "prod") {
  Sentry.init({
    Vue,
    dsn: "https://538972bf827da046d4ca919bbac13942@o4504287365496832.ingest.sentry.io/4505863794982912",
    integrations: [
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      }),
      new Sentry.Replay(),
    ],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,

    // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ["localhost", /^https:\/\/in-the-loop\.co\/api/],

    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });
}

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");

export {
  db,
  analytics,
  getFunctions,
  httpsCallable,
  auth,
  signOut,
  deleteUser,
  getRedirectResult,
  doc,
  setDoc,
  addDoc,
  collection,
  collectionGroup,
  increment,
  where,
  query,
  getDocs,
  getDoc,
  deleteDoc,
  getAdditionalUserInfo,
  updateEmail,
  EmailAuthProvider,
  updateDoc,
  arrayUnion,
  arrayRemove,
  database,
  ref,
  set,
  storage,
  uploadBytes,
};
// function getMessaging(app: FirebaseApp) {
//   throw new Error("Function not implemented.");
// }
