import {
  collection,
  doc,
  getDocs,
  query,
  setDoc,
  where,
} from "firebase/firestore";
import { db } from "@/main";

export default class ReviewsService {
  async getAllReviews() {
    const reviewsRef = collection(db, "reviews");
    const querySnapshot = await getDocs(reviewsRef);
    return querySnapshot.docs.map((doc) => doc.data());
  }

  async saveReview(review: any) {
    await setDoc(doc(db, "reviews", review.id), {
      ...review,
    });
    return review;
  }

  async getReviewsByItemId(itemId: string) {
    const reviewsRef = collection(db, "reviews");
    const q = query(reviewsRef, where("item_id", "==", itemId));
    const querySnapshot = await getDocs(q);
    if (querySnapshot.size === 0) {
      return [];
    }
    return querySnapshot.docs.map((doc) => doc.data());
  }
}
