import dayjs from "dayjs";

export class HelperFunctions {
  public static generateNewId(): string {
    // tslint:disable:no-bitwise
    const firstPart = Math.random() * 46656 || 0;
    const secondPart = Math.random() * 46656 || 0;
    const prefix = String.fromCharCode(Math.floor(Math.random() * 26) + 97);
    return (
      prefix +
      ("000" + firstPart.toString(36)).slice(-3) +
      ("000" + secondPart.toString(36)).slice(-3)
    );
  }

  public static scrollToTop() {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Use 'auto' for instant scrolling without smooth animation
    });
  }

  public static truncateString(str: string, maxLength: number) {
    return str.length > maxLength ? `${str.substring(0, maxLength)}...` : str;
  }

  public static isAMorPM(timeString: string) {
    const time = new Date(`2023-09-07T${timeString}`);
    const hour = time.getHours();

    return hour < 12 ? "AM" : "PM";
  }

  public static isEmpty(obj: object) {
    // console.log(obj);
    // for (const prop in obj) {
    //   console.log(prop);
    //   if (Object.prototype.hasOwnProperty.call(obj, prop)) {
    //     return false;
    //   }
    // }
    // return JSON.stringify(obj) === JSON.stringify({});
    return Object.keys(obj).length === 0;
  }

  public static getLocation() {
    return new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(resolve, reject);
    });
  }

  public static calculateAverage(...numbers: number[]) {
    // console.log(numbers, "numbers");
    if (numbers.length === 0) {
      return 0; // To handle the case when no numbers are provided
    }

    const sum = numbers.reduce(
      (accumulator, currentValue) => accumulator + currentValue
    );
    const average = sum / numbers.length;
    console.log(average, "average");
    return average;
  }

  public static capitaliseFirstLetter(string: string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  public static formatUnixDate(unixDate: number) {
    const unixTime = unixDate.valueOf();
    const d = new Date(unixTime);
    return d.toUTCString();
  }

  public static dateFormat(dateString: string) {
    const date = new Date(dateString);
    const year = date.getFullYear();
    // const month = String(date.getMonth() + 1).padStart(2, "0"); // Add leading zero if needed
    const month = date.toLocaleString("default", { month: "long" });
    //const day = String(date.getDate()).padStart(2, "0"); // Add leading zero if needed
    return `${month} ${year}`;
  }

  public static formatDate(dateString: string) {
    const dateStr = dateString;
    const [dd, mm, yyyy, hh, min, sec] = dateStr.split(/[- :]/);
    const date = new Date(
      Number(yyyy),
      Number(mm) - 1,
      Number(dd),
      Number(hh),
      Number(min),
      Number(sec)
    );
    return date;
  }

  public static generatePickUpTimes(): { time: string; selected: boolean }[] {
    const startTime = new Date();
    startTime.setHours(9, 0, 0); // set start time to 9am

    const endTime = new Date();
    endTime.setHours(17, 30, 0); // set end time to 5pm (17:00)

    const timeArray = [];

    while (startTime < endTime) {
      timeArray.push({
        time: dayjs(startTime).format("HH:mm"),
        selected: false,
      }); // formatting to just time but could be whole data and time
      startTime.setMinutes(startTime.getMinutes() + 30); // increment time by 30 minutes
    }
    return timeArray;
  }
}
