
import { Vue, Component, Watch, Prop } from "vue-property-decorator";

import categoryService from "@/services/categories-service";
import { ICategory } from "@/interface/interface";

@Component({})
export default class CategoryFilterComponent extends Vue {
  categoryService = new categoryService();
  @Prop({ default: false, type: Boolean }) dropDown!: boolean;
  @Prop({ default: false, type: Boolean }) clearAll!: boolean;
  selected: { category: string; icon: string } | null = null;

  @Watch("clearAll") clearingFilters() {
    this.selected = null;
  }

  get categories(): ICategory[] {
    return this.categoryService.getCategories();
  }

  // TODO: not sure this is being used. Check if anything is being emitted
  selectedCategory(): void {
    this.$emit("search-by-tag", this.selected?.category);
  }

  searchByTag(tag: string) {
    if (this.$router.currentRoute.name === "home") {
      this.$router.push({
        name: "listings",
        query: { tag: tag.toLowerCase() },
      });
    }
    // TODO: not sure this is being used. Check if anything is being emitted
    this.$emit("search-by-tag", tag);
  }
}
