
import { Vue, Component, Prop } from "vue-property-decorator";
import ItemComponent from "@/components/item-component.vue";
import { IItem } from "@/interface/interface";

@Component({
  components: {
    ItemComponent,
  },
})
export default class MostViewedComponent extends Vue {
  @Prop({ default: [], type: Array }) items!: IItem[];
}
