import store from "../store";
import router from "../router";
import ItemService from "./item-service";
import AuthService from "./auth-service";
import {
  auth,
  deleteDoc,
  getFunctions,
  httpsCallable,
  updateDoc,
} from "@/main";
import {
  db,
  setDoc,
  doc,
  collection,
  increment,
  where,
  query,
  getDocs,
} from "../main";

// TODO: NEED TO LOOK AT MAKING EVERYTHING FAIL GRACEFULLY

import { IUser } from "@/interface/interface";
import { HelperFunctions } from "@/helpers/helpers";

const itemService = new ItemService();
// const authService = new AuthService();
export default class UserService {
  async getAllUsers() {
    const userRef = collection(db, `users_${process.env.VUE_APP_ENV}`);
    const querySnapshot = await getDocs(userRef);
    store.commit(
      "SET_USERS",
      querySnapshot.docs.map((doc) => doc.data())
    );
    return querySnapshot.docs.map((doc) => doc.data());
  }

  async saveUser(user: IUser) {
    store.commit("SET_USER", user);
    store.commit("SET_AUTH", {
      role: store.state.user.role,
      user_id: store.state.user.id,
    });
    await setDoc(doc(db, `users_${process.env.VUE_APP_ENV}`, user.id), {
      ...user,
      visitCount: increment(1),
    });
    return user;
  }

  async getUserByEmail(email: string | null) {
    const userRef = collection(db, `users_${process.env.VUE_APP_ENV}`);
    const q = query(userRef, where("email", "==", email));
    const querySnapshot = await getDocs(q);
    if (querySnapshot.empty) {
      store.commit("SET_USER", {});
      store.commit("SET_ALERT", {
        title: "No user found",
        alert: "Your login details didn't match any users. Please try again.",
        type: "error",
      });
      return null;
    } else {
      const user = querySnapshot.docs.map((doc) => doc.data())[0];
      store.commit("SET_USER", user);
      store.commit("SET_LOGGED_IN", true);
      if (user.visitCount > 1) {
        itemService.getUserItems(user.id);
      }
      return user;
    }
  }

  async getUserPublicInfoById(userId: string) {
    const userRef = collection(db, `users_${process.env.VUE_APP_ENV}`);
    const q = query(userRef, where("id", "==", userId));
    const querySnapshot = await getDocs(q);
    // console.log(querySnapshot.empty, "querySnapshot.empty");
    // console.log(querySnapshot.size, "querySnapshot.size");
    const user = querySnapshot.docs.map((doc) => doc.data())[0];
    return {
      uid: user?.uid,
      // username: user?.firstname + " " + user?.lastname,
      username: user?.firstname,
      bio: user?.bio,
      id: user?.id,
      avatar: user?.avatar,
      member_since: HelperFunctions.dateFormat(user?.createdTime),
    };
  }

  async getUserById(userId: string, addToStore?: boolean) {
    const userRef = collection(db, `users_${process.env.VUE_APP_ENV}`);
    const q = query(userRef, where("id", "==", userId));
    const querySnapshot = await getDocs(q);
    const user = querySnapshot.docs.map((doc) => doc.data())[0];
    if (addToStore) {
      store.commit("SET_USER", user);
    }
    return user;
  }

  async updateUser(user: any): Promise<IUser> {
    const userRef = doc(db, `users_${process.env.VUE_APP_ENV}`, user.id);
    await updateDoc(userRef, user);
    const usersRef = collection(db, `users_${process.env.VUE_APP_ENV}`);
    const querySnapshot = await getDocs(usersRef);
    const users = querySnapshot.docs.map((doc) => doc.data());
    store.commit("SET_USERS", users);
    return user;
  }

  async checkUserAssociationsInBookings(userId: string) {
    try {
      const functions = getFunctions();
      const checkUserAssociationsInBookingsFunction = httpsCallable(
        functions,
        "userFunctions-checkUserAssociationsInBookings"
      );
      const result = await checkUserAssociationsInBookingsFunction(userId);
      console.log(result.data);
      // TODO: Need to blank our the user details in the associated bookings
    } catch (error) {
      console.error("Error getting associated bookings", error);
    }
  }

  async updatePassword(uid: string, password: string) {
    const headers = new Headers();
    headers.append("Content-Type", "application/x-www-form-urlencoded");
    const urlencoded = new URLSearchParams();
    urlencoded.append("uid", uid);
    urlencoded.append("password", password);

    const requestOptions = {
      method: "POST",
      headers: headers,
      body: urlencoded,
    };

    fetch(
      "https://us-central1-waste-not-13cef.cloudfunctions.net/userFunctions-updatePassword",
      requestOptions
    )
      .then((response) => response.json())
      .then(() => {
        store.commit("SET_ALERT", {
          title: "Password updated",
          alert: "Your password has been successfully updated.",
          type: "success",
        });
        router.push("/login");

        store.commit("SET_LOGGED_IN", false);
        store.commit("SET_USER", null);
        store.commit("SET_OWNER", null);
        store.commit("SET_LOADING", false);
        store.commit("SET_USER_ITEMS", []);
        store.commit("SET_ALL_ITEMS", []);
        store.commit("SET_MASTER_USER", {});
        store.commit("SET_MESSAGES", []);
        store.commit("SET_BOOKINGS", []);
        store.commit("SET_NOTIFICATIONS", []);
        store.commit("SET_BOOKINGS_HISTORY", []);
        store.commit("SET_ITEM", {});
        store.commit("SET_AUTH", {});
        store.commit("SET_MESSAGE_COUNT", 0);
        store.commit("SET_NOTIFICATION_PANEL_OPEN", false);
        store.commit("SET_PROFILE_PANEL_OPEN", false);
        store.commit("SET_LOCATION_COORDINATES", {});
        localStorage.clear();
      })
      .catch((error) => {
        throw new Error("Error updating password");
      });
  }

  async deleteUserById(userId: string) {
    const userRef = collection(db, `users_${process.env.VUE_APP_ENV}`);
    const q = query(userRef, where("id", "==", userId));
    const querySnapshot = await getDocs(q);
    const user = querySnapshot.docs.map((doc) => doc.data())[0];
    const userDoc = doc(db, `users_${process.env.VUE_APP_ENV}`, user.id);
    await deleteDoc(userDoc);
    this.getAllUsers();
  }

  async deleteAuthenticationUser(userUID: string | undefined): Promise<void> {
    console.log(userUID, "userUID");
    try {
      const functions = getFunctions();
      const deleteUserFunction = httpsCallable(
        functions,
        "userFunctions-deleteUser"
      );
      const result: any = await deleteUserFunction(userUID);
      console.log(result.data.message);
    } catch (error) {
      console.error("Error deleting user", error);
    }
  }

  async deleteUser() {
    const user = auth.currentUser;
    user
      ?.delete()
      .then(async () => {
        await deleteDoc(
          doc(db, `users_${process.env.VUE_APP_ENV}`, store.state.user.id)
        );
      })
      .finally(() => {
        store.commit("SET_ALERT", {
          title: "Profile deleted",
          alert: "Profile successfully deleted.",
          type: "success",
        });
        store.commit("SET_LOGGED_IN", false);
        store.commit("SET_USER", null);
        store.commit("SET_LOADING", false);
        router.push("/");
      });
  }
}
