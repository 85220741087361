
import { Vue, Component, Prop } from "vue-property-decorator";

@Component({})
export default class BannerComponent extends Vue {
  @Prop({ default: "" }) scheme!: "";
  @Prop({ default: "" }) backgroundImage!: "";
  @Prop({ default: "" }) firstTitle!: "";
  @Prop({ default: "" }) secondTitle!: "";
  @Prop({ default: "" }) firstSubTitle!: "";
  @Prop({ default: "" }) secondSubTitle!: "";
  @Prop({ default: "" }) size!: "";
}
