import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);

const vuetify = new Vuetify({
  theme: {
    themes: {
      light: {
        primary: "#4B4B4B",
        secondary: "#b0bec5",
        anchor: "#ffb74d",
      },
    },
  },
});

export default new Vuetify({});
