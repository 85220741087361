import store from "../store";
import { collection, db, doc, getDocs } from "../main";

export default class SportsSerice {
  async getAllSports() {
    const sportRef = collection(db, "sports");
    const querySnapshot = await getDocs(sportRef);
    store.commit(
      "SET_SPORTS",
      querySnapshot.docs.map((doc) => doc.data())
    );
    return querySnapshot.docs.map((doc) => doc.data());
  }
}
