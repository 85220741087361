
import { Vue, Component, Prop } from "vue-property-decorator";
import { HelperFunctions } from "@/helpers/helpers";
import reviewsService from "@/services/reviews-services";
import itemService from "@/services/item-service";
import { IItem } from "@/interface/interface";

@Component({
  components: {
    ShareComponent: () => import("@/components/generic/share-component.vue"),
    FavouriteComponent: () => import("@/components/favourite-component.vue"),
    RatingComponent: () => import("@/components/generic/rating-component.vue"),
  },
})
export default class ItemComponent extends Vue {
  reviewsService = new reviewsService();
  itemService = new itemService();
  rating = 0;
  @Prop({ type: Object }) rental!: IItem;
  @Prop({ default: true, type: Boolean }) image!: boolean;
  @Prop() grid!: boolean;

  reviews: any = [];

  // get rating(): number {
  //   return (this.rental.rating?.current_rating * 5) / 100;
  // }

  get item(): IItem & { link_text: string } {
    return {
      ...this.rental,
      link_text: this.rental.name.replace(/\s+/g, "-").toLowerCase(),
    };
  }

  get truncatedTitle(): string {
    return HelperFunctions.truncateString(this.rental.name, 12);
  }

  get description(): string {
    return HelperFunctions.truncateString(this.rental.description, 70);
  }

  highlightMarker(item: IItem): void {
    const position = {
      position: {
        lat: Number(item.location.lat),
        lng: Number(item.location.lng),
      },
    };
    this.$store.commit("SET_ITEM_HIGHLIGHTED", position);
  }

  viewItem(): void {
    this.$router.push(
      `/item/${this.item.link_text}/${this.item.id}?edit=false`
    );
  }

  viewItemIncrement(item: IItem): void {
    this.$router.push(`/item/${item.link_text}/${item.id}?edit=false`);
    this.itemService.incrementViewedItem(item);
  }

  getItemReviews(): any {
    this.reviewsService.getReviewsByItemId(this.rental.id).then((items) => {
      const reviews = items
        .filter((item: any) => item.rent_text !== "")
        .map((x) => {
          x.rent_text = HelperFunctions.truncateString(x.rent_text, 60);
          return x;
        });

      this.reviews = reviews;
    });
  }

  loadNewItem(): void {
    if (this.$router.currentRoute.name === "home") {
      this.$router.push(`/item/${this.item.name}/${this.item.id}?edit=false`);
    } else if (this.$router.currentRoute.name === "item") {
      //TODO: NEED TO LOOK IF THERE IS ANOTHER WAY OF RELOADING THE ITEM WITHUT RELOADING THE WHOLE PAGE
      this.$router
        .push(`/item/${this.item.name}/${this.item.id}?edit=false`)
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        .catch(() => {});
      // window.location.reload();
    }
  }

  created(): void {
    this.getItemReviews();
    this.rating = (this.rental.rating?.current_rating * 5) / 100;
  }
}
