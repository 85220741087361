
import { Vue, Component, Watch } from "vue-property-decorator";
import ItemComponent from "@/components/item-component.vue";
import BannerComponent from "@/components/generic/banner-component.vue";
import NewsletterComponent from "@/components/generic/newsletter-component.vue";
import CategoryFilterComponent from "@/components/filters/category-filter-component.vue";
import MostViewedComponent from "@/components/most-viewed-component.vue";
import itemService from "@/services/item-service";

import { IItem } from "@/interface/interface";
import customParseFormat from "dayjs/plugin/customParseFormat";

import config, { AppConfig } from "@/configs/config-loop";

import dayjs from "dayjs";
dayjs.extend(customParseFormat);

@Component({
  components: {
    ItemComponent,
    CategoryFilterComponent,
    BannerComponent,
    NewsletterComponent,
    MostViewedComponent,
  },
})
export default class HomeViewComponent extends Vue {
  config: AppConfig = config;
  itemService = new itemService();

  sortedByDateOrder: any = [];
  likedItems: any = [];

  homeBannerImage = "";
  bannerStyle = "light";

  location = "";
  returnDate = "";
  date = "";
  menu_pick_up = false;
  menu_drop_off = false;
  pick_up = dayjs().format("YYYY-MM-DD");
  drop_off = dayjs().add(1, "day").format("YYYY-MM-DD");
  hasPosition = true;
  positionLoading = false;
  geocodingEndpoint = "";

  numberOfFavorites = 3;
  numberOfItems = 3;
  mostViewedItems: any = [];
  likedColumns = 0;
  position = {};

  get showFavorites(): boolean {
    return this.$store.state.showFavorites;
  }

  get formattedPickUpDate(): string {
    return dayjs(this.pick_up).format("DD-MM-YYYY");
  }

  get formattedReturnDate(): string {
    return dayjs(this.drop_off).format("DD-MM-YYYY");
  }

  @Watch("pick_up") pickUpDateChanged(): void {
    this.date = this.formattedPickUpDate;
  }

  @Watch("drop_off") dropOffDateChanged(): void {
    this.returnDate = this.formattedReturnDate;
  }

  @Watch("$store.state.user") userLoaded(): void {
    if (this.$store.getters.loggedIn) {
      this.likedItems = this.$store.state.user.favourites?.map((item: any) => {
        return this.sortedByDateOrder.find((i: any) => i.id === item.id);
      });
    }
  }

  viewRentals(): void {
    if (this.location) {
      this.updateLocation().then(() => {
        this.$router.push("/listings");
      });
      this.$store.commit("SET_SEARCH_CRITERIA", {
        location: this.position,
        pick_up: this.pick_up,
        drop_off: this.drop_off,
      });
    } else {
      this.$router.push("/listings");
    }
  }

  showMoreItems(): number {
    this.numberOfItems += 3;
    return this.numberOfFavorites;
  }

  async updateLocation(): Promise<void> {
    const geocodingEndpoint = `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
      this.location
    )}&key=AIzaSyC1N2S7dDurbdNOIHCQ5NtHbzqx0OWS6Is`;

    try {
      const response = await fetch(
        this.location ? geocodingEndpoint : this.geocodingEndpoint
      );
      const data = await response.json();
      if (data.status === "OK" && data.results.length > 0) {
        const { lat, lng } = data.results[0].geometry.location;
        this.position = { lat: lat, lng: lng };
        // store search criteria
        this.$store.commit("SET_SEARCH_CRITERIA", {
          location: { lat: lat, lng: lng },
          pick_up: this.pick_up,
          drop_off: this.drop_off,
        });
      } else {
        throw new Error("Unable to geocode the location.");
      }
    } catch (error) {
      console.error(error);
    }
  }

  getLocation(): void {
    this.hasPosition = false;
    this.positionLoading = true;
    navigator.geolocation.getCurrentPosition(async (position) => {
      console.log(position);
      if (position) {
        this.geocodingEndpoint = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${position.coords.latitude},${position.coords.longitude}&key=AIzaSyC1N2S7dDurbdNOIHCQ5NtHbzqx0OWS6Is`;
        this.hasPosition = true;
        this.positionLoading = false;
        // this.$store.commit("SET_LOCATION", {
        //   lat: Number(position?.coords.latitude),
        //   lng: Number(position?.coords.longitude),
        // });

        this.position = {
          lat: Number(position.coords.latitude),
          lng: Number(position.coords.longitude),
        };

        try {
          const response = await fetch(this.geocodingEndpoint);
          const data = await response.json();
          const { lat, lng } = data.results[0].geometry.location;
          this.$store.commit("SET_SEARCH_CRITERIA", {
            location: { lat: lat, lng: lng },
            pick_up: this.pick_up,
            drop_off: this.drop_off,
          });

          this.location = data.results
            .filter((location: any) =>
              location.types.find((item: any) => item === "postal_town")
            )
            .find((x: any) => x).formatted_address;
        } catch (error) {
          console.error(error);
        }
      }
    });
  }

  getMostViewed(): void {
    const idMap = new Map(
      this.$store.state.allItems.map((item: IItem) => [item.id, item])
    );
    this.itemService.mostViewedItems().then((data) => {
      this.mostViewedItems = data.map((item1) => {
        const item2 = idMap.get(item1.id);
        if (item2) {
          const mergedItem = Object.assign({}, item1, item2);
          return mergedItem;
        }
        return item1;
      });
    });
  }

  created(): void {
    // this.homeBannerImage = require(`@/assets/images/${this.$imageShuffle()}`);
    this.homeBannerImage = config.branding.banners.bannerImage;
    if (this.homeBannerImage.includes("header_5")) {
      this.bannerStyle = "dark";
    }
    this.itemService.getAllItems().then((items) => {
      const itemsWithLinkText = items.map((item: any) => ({
        ...item,
        link_text: item.name.replace(/\s+/g, "-").toLowerCase(),
      }));
      this.sortedByDateOrder = this.$orderBy(itemsWithLinkText, "desc", "item");
      this.sortedByDateOrder = this.sortedByDateOrder.filter(
        (item: any) => item.approved
      );
      this.getMostViewed();
      if (this.$store.getters.loggedIn) {
        this.likedItems = this.$store.state.user.favourites.map((item: any) => {
          return this.sortedByDateOrder.find((i: any) => i.id === item.id);
        });

        switch (this.likedItems.length) {
          case 1:
            console.log(`You have ${this.likedItems.length} liked item.`);
            this.likedColumns = this.likedItems.length;
            break;
          case 2:
            console.log(`You have ${this.likedItems.length} liked item.`);
            this.likedColumns = this.likedItems.length;
            break;
          case 3:
            console.log(`You have ${this.likedItems.length} liked item. 3`);
            this.likedColumns = this.likedItems.length;
            break;
          case 4:
            console.log(`You have ${this.likedItems.length} liked items`);
            this.likedColumns = this.likedItems.length;
            break;
          default:
            console.log(
              `You have ${this.likedItems.length} liked items. Handle this case separately if needed.`
            );
            break;
        }
      }
    });
  }
}
