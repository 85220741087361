
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import {
  getStorage,
  ref,
  getDownloadURL,
  uploadBytesResumable,
} from "firebase/storage";
import { IUser } from "@/interface/interface";

@Component({})
export default class AvatarComponent extends Vue {
  @Prop({ default: () => ({}), type: Object }) user!: IUser;
  @Prop({ default: false, type: Boolean }) small!: boolean;
  @Prop({ default: true, type: Boolean }) overlay!: boolean;
  @Prop({ default: false, type: Boolean }) isMenuAvatar!: boolean;
  selectedFile!: File;
  progress = 0;
  hasAvatar = false;

  get avatarURL(): string {
    if (this.user?.avatar) {
      return this.user?.avatar;
    } else {
      return "";
    }
  }

  get userInitial(): string {
    return this.user.firstname.charAt(0) + this.user.lastname.charAt(0);
  }

  @Watch("$store.state.owner") ownerChanged() {
    if (this.$store.state.owner?.avatar) {
      this.hasAvatar = true;
    } else {
      this.hasAvatar = false;
    }
  }

  @Watch("$store.state.user") userChanged() {
    if (this.$store.state.user?.avatar) {
      this.hasAvatar = true;
    } else {
      this.hasAvatar = false;
    }
  }

  handleFileChange(e: any) {
    this.selectedFile = e.target.files[0];
    this.uploadAvatar();
  }

  uploadAvatar(): void {
    /** @type {any} */
    const metadata = {
      contentType: "image/*",
    };

    const storage = getStorage();
    const storageRef = ref(storage, "avatars/" + this.selectedFile.name);
    const uploadTask = uploadBytesResumable(
      storageRef,
      this.selectedFile,
      metadata
    );

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
        const progress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        console.log("Upload is " + progress + "% done");
        this.progress = progress;
        switch (snapshot.state) {
          case "paused":
            console.log("Upload is paused");
            break;
          case "running":
            console.log("Upload is running");
            break;
        }
      },
      (error) => {
        // A full list of error codes is available at
        // https://firebase.google.com/docs/storage/web/handle-errors
        switch (error.code) {
          case "storage/unauthorized":
            break;
          case "storage/canceled":
            break;
          case "storage/unknown":
            break;
        }
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          console.log(downloadURL, "here");
          this.hasAvatar = true;
          // this.avatarURL = downloadURL;
          this.$store.state.user.avatar = downloadURL;
        });
      }
    );
  }

  triggerFileInput() {
    (this.$refs.fileInput as HTMLInputElement).click();
  }

  created(): void {
    console.log(this.user, "=======");
  }
}
