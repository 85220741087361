
import { Vue, Component, Watch } from "vue-property-decorator";
import { HelperFunctions } from "@/helpers/helpers";

@Component({})
export default class AlertComponent extends Vue {
  @Watch("$store.state.alert") onAlertChange() {
    setTimeout(() => {
      this.clearAlert();
    }, 3200);
  }

  get alertIsEmpty(): boolean {
    return HelperFunctions.isEmpty(this.$store.state.alert);
  }

  get typeClass(): string {
    return this.alertIsEmpty
      ? ""
      : `c-alerts__${this.$store.state.alert?.type}`;
  }

  clearAlert(): void {
    this.$store.commit("SET_ALERT", null);
  }

  created(): void {
    this.onAlertChange();
  }
}
