
import { Vue, Component } from "vue-property-decorator";
import AuthService from "@/services/auth-service";
import NotificationService from "@/services/notification-service";
import { HelperFunctions } from "@/helpers/helpers";

import config, { AppConfig } from "@/configs/config-loop";

import AvatarComponent from "../avatar-component.vue";
import { INotification } from "@/interface/interface";

@Component({
  components: {
    AvatarComponent,
  },
  filters: {
    unixToDate(value: number): string {
      return HelperFunctions.formatUnixDate(value);
    },
  },
})
export default class extends Vue {
  active = false;
  profilePanelVisible = false;
  notificationPanelVisible = false;
  notificationViewedValue = false;

  config: AppConfig = config;

  authService = new AuthService();
  notificationService = new NotificationService();

  get profileUrl(): string {
    return "/profile/" + this.$store.state?.user?.id;
  }

  get numberOfNotifications(): number {
    return this.$store.state.notifications.length;
  }

  get notifications(): INotification[] {
    return this.$store.state.notifications;
  }

  get loggedIn(): boolean {
    return this.$store.state.loggedIn;
  }

  get itemCreateUrl(): string {
    return "/item-upload/" + this.$store.state.user?.uid;
  }

  createItem(): void {
    this.menuItemSelected();
    if (this.loggedIn) {
      if (!this.$store.state.user.stripe_account_linked) {
        this.$store.commit("SET_ALERT", {
          title: "Stripe account not linked",
          alert: `Please link your account to Stripe in your <a class="no-decoration font-weight-bold" href="/dashboard/${this.$store.state.user.id}">dashboard</a> payment preferences before adding an item`,
          type: "warning",
        });
        return;
      } else {
        // this.$store.commit("SET_ITEM", {});
        this.$router.push(`/item-upload/${this.$store.state.user.uid}`);
      }
      return;
    } else {
      this.$store.commit("SET_ALERT", {
        title: "Login to continue",
        alert:
          "You need to have an account and be logged in to continue.</br> You can login in  <a class='no-decoration font-weight-bold' href='/login'>here</a> if you already haven account, or sign up <a class='no-decoration font-weight-bold' href='/sign-up'>here</a>",
        type: "warning",
      });
      return;
    }
  }

  menuItemSelected(): void {
    if (this.active) {
      this.toggleMenu();
    }
    if (this.notificationPanelVisible) {
      this.toggleNotificationPanel();
    }
    if (this.profilePanelVisible) {
      this.toggleProfilePanel();
    }
    this.$store.commit("SET_NOTIFICATION_PANEL_OPEN", false);
    this.$store.commit("SET_PROFILE_PANEL_OPEN", false);
    document.documentElement.style.overflow = ""; // Restore default scrolling on <html> element
    document.body.style.overflow = ""; // Restore default scrolling on <body> element
  }

  viewFavorites(): void {
    this.$router.push(`/dashboard/${this.$store.state.user.id}?favorites=true`);
    // TODO: Add favorites page
    // this.$router.push("/favorites");
    // if (this.$store.state.showFavorites) {
    //   this.$store.commit("SET_FAVORITES", false);
    // } else {
    //   this.$store.commit("SET_FAVORITES", true);
    // }
  }

  closeMenu(): void {
    this.toggleMenu();
  }

  logout(): void {
    this.authService.signOut();
  }

  updateNotifications(notification: INotification): void {
    this.notificationPanelVisible = false;
    document.documentElement.style.overflow = ""; // Restore default scrolling on <html> element
    document.body.style.overflow = ""; // Restore default scrolling on <body> element
    this.notificationService.updateNotification(notification);
  }

  viewAllNotifications(): void {
    this.notificationService.getAllNotifications(
      this.$store.state.user.id,
      this.notificationViewedValue
    );
  }

  toggleMenu(): void {
    this.active = !this.active;
    console.log(this.active, "=====");
    if (this.notificationPanelVisible) {
      this.notificationPanelVisible = false;
      this.$store.commit(
        "SET_NOTIFICATION_PANEL_OPEN",
        this.notificationPanelVisible
      );
    }
    if (this.profilePanelVisible) {
      this.profilePanelVisible = false;
      this.$store.commit("SET_PROFILE_PANEL_OPEN", this.profilePanelVisible);
    }

    if (this.active) {
      document.documentElement.style.overflow = "hidden"; // Prevent scrolling on <html> element
      document.body.style.overflow = "hidden"; // Prevent scrolling on <body> element
    } else {
      document.documentElement.style.overflow = ""; // Restore default scrolling on <html> element
      document.body.style.overflow = ""; // Restore default scrolling on <body> element
    }
  }

  toggleNotificationPanel(): void {
    this.notificationPanelVisible = !this.notificationPanelVisible;
    this.$store.commit(
      "SET_NOTIFICATION_PANEL_OPEN",
      this.notificationPanelVisible
    );
    if (this.active) {
      this.active = false;
    }
    if (this.profilePanelVisible) {
      this.profilePanelVisible = false;
      this.$store.commit("SET_PROFILE_PANEL_OPEN", this.profilePanelVisible);
    }

    if (this.notificationPanelVisible) {
      document.documentElement.style.overflow = "hidden"; // Prevent scrolling on <html> element
      document.body.style.overflow = "hidden"; // Prevent scrolling on <body> element
    } else {
      document.documentElement.style.overflow = ""; // Restore default scrolling on <html> element
      document.body.style.overflow = ""; // Restore default scrolling on <body> element
    }
  }

  toggleProfilePanel(): void {
    this.profilePanelVisible = !this.profilePanelVisible;
    if (this.active) {
      this.active = false;
    }
    if (this.notificationPanelVisible) {
      this.notificationPanelVisible = false;
      this.$store.commit(
        "SET_NOTIFICATION_PANEL_OPEN",
        this.notificationPanelVisible
      );
    }

    if (this.profilePanelVisible) {
      document.documentElement.style.overflow = "hidden"; // Prevent scrolling on <html> element
      document.body.style.overflow = "hidden"; // Prevent scrolling on <body> element
    } else {
      document.documentElement.style.overflow = ""; // Restore default scrolling on <html> element
      document.body.style.overflow = ""; // Restore default scrolling on <body> element
    }

    this.$store.commit("SET_PROFILE_PANEL_OPEN", this.profilePanelVisible);
  }

  handleBodyClick(): void {
    if (this.notificationPanelVisible) {
      this.toggleNotificationPanel();
    }
    if (this.profilePanelVisible) {
      this.toggleProfilePanel();
    }
    if (this.active) {
      this.toggleMenu();
    }
  }

  mounted(): void {
    document
      .getElementById("main-container")
      ?.addEventListener("click", this.handleBodyClick);
  }

  beforeDestroy() {
    document
      .getElementById("app")
      ?.removeEventListener("click", this.handleBodyClick);
  }
}
