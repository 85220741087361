export interface ThemeConfig {
  primaryColor: string;
  secondaryColor: string;
  // Add other styling properties as needed
}

export interface firebaseConfig {
  apiKey: string;
  authDomain: string;
  databaseURL: string;
  projectId: string;
  storageBucket: string;
  messagingSenderId: string;
  appId: string;
  measurementId: string;
}

export interface GoogleFontConfig {
  fontFamilies: string[];
}

export interface BannerConfig {
  bannerImage: string;
}

export interface BrandingConfig {
  appName: string;
  appLogoUrl: string;
  // ... (other branding details)
  googleFonts: GoogleFontConfig;
  styleSheet: string;
  banners: BannerConfig;
}

export interface ComponentsConfig {
  homeView: {
    iconSection: {
      visible: boolean;
      images: string[];
    };
  };
  // Add more component flags
}

export interface AppConfig {
  theme: ThemeConfig;
  branding: BrandingConfig;
  components: ComponentsConfig;
  firebaseConfig: firebaseConfig;
}

const config: AppConfig = {
  firebaseConfig: {
    apiKey: "AIzaSyC1N2S7dDurbdNOIHCQ5NtHbzqx0OWS6Is",
    authDomain: "waste-not-13cef.firebaseapp.com",
    projectId: "waste-not-13cef",
    storageBucket: "waste-not-13cef.appspot.com",
    databaseURL: "https://waste-not-13cef-default-rtdb.firebaseio.com",
    messagingSenderId: "16992484597",
    appId: "1:16992484597:web:b757da6d7d2fff60e319bc",
    measurementId: "G-VE3EP6LESX",
  },
  theme: {
    primaryColor: "#FF5733",
    secondaryColor: "#4286f4",
    // Other styling options
  },
  //fonts.googleapis.com/css?family=Montserrat%7CLove+Ya+Like+A+Sister%7CRoboto+Slab:100,300,400,700&subset=latin,latin,latin
  // https://www.google.com/images/branding/googlelogo/1x/googlelogo_color_272x92dp.png
  branding: {
    appName: "Loop",
    appLogoUrl: "loop-logo_thin.png",
    // appLogoUrl: "images/loop-logo_thin.png",
    // ... (other branding details)
    googleFonts: {
      fontFamilies: [
        "Montserrat",
        "Love+Ya+Like+A+Sister",
        "Roboto+Slab:100,300,400,700",
        "Open+Sans:400,700",
        "Roboto:100, 300, 400, 500, 700, 900",
      ],
    },
    styleSheet: "style-1.css",
    banners: {
      bannerImage: "images/home_banner.png",
    },
  },
  components: {
    homeView: {
      iconSection: {
        images: [
          "images/homepage-images_1.png",
          "images/homepage-images_2.png",
          "images/homepage-images_3.png",
        ],
        visible: true,
      },
    },
    // Add more component flags
  },
};

export default config;
