import { ICategory } from "@/interface/interface";
import store from "../store";
import { collection, db, doc, getDocs } from "../main";
export default class CategoryService {
  async getActivitiies(): Promise<any[]> {
    const sportRef = collection(db, "sports");
    const querySnapshot = await getDocs(sportRef);
    store.commit(
      "SET_SPORTS",
      querySnapshot.docs.map((doc) => doc.data())
    );
    return querySnapshot.docs.map((doc) => doc.data());
  }
  getCategories() {
    // const categories: ICategory[] = [
    //   {
    //     icon: "mdi-bike",
    //     category: "triathlon",
    //   },
    //   {
    //     icon: "mdi-run",
    //     category: "running",
    //   },
    //   {
    //     icon: "mdi-swim",
    //     category: "swimming",
    //   },
    //   {
    //     icon: "mdi-tent",
    //     category: "camping",
    //   },
    //   {
    //     icon: "mdi-weight-lifter",
    //     category: "strength",
    //   },
    //   {
    //     icon: "mdi-ski",
    //     category: "skiing",
    //   },
    //   {
    //     icon: "mdi-bicycle",
    //     category: "cycling",
    //   },
    //   {
    //     icon: "mdi-diving-scuba-mask",
    //     category: "diving",
    //   },
    //   {
    //     icon: "mdi-carabiner",
    //     category: "climbing",
    //   },
    //   {
    //     icon: "mdi-human-male-child",
    //     category: "kids",
    //   },
    //   {
    //     icon: "mdi-kayaking",
    //     category: "Kayaking / SUP",
    //   },
    //   {
    //     icon: "mdi-surfing",
    //     category: "Surfing",
    //   },
    // ];
    const categories: ICategory[] = [
      {
        icon: "mdi-run",
        category: "Sport",
        show: true,
      },
      {
        icon: "mdi-swim",
        category: "Watersport",
        show: true,
      },

      {
        icon: "mdi-hiking",
        category: "Outdoor Activity",
        show: true,
      },
      {
        icon: "mdi-ski",
        category: "Winter Sports",
        show: true,
      },
      {
        icon: "mdi-tent",
        category: "Camping",
        show: true,
      },
      {
        icon: "mdi-human-male-child",
        category: "Kids",
        show: false,
      },
      {
        icon: "mdi-bike",
        category: "Cycling",
        show: true,
      },
    ];
    return categories;
  }
}
