
import { HelperFunctions } from "@/helpers/helpers";
import { db } from "@/main";
import { doc, setDoc } from "firebase/firestore";
import { Vue, Component } from "vue-property-decorator";

@Component({})
export default class NewsletterComponent extends Vue {
  email = "";

  emailRules = [
    (v: string) => !!v || "Email is required",
    (v: string) => /.+@.+\..+/.test(v) || "Email must be valid",
  ];

  async signUp() {
    console.log("sign up");
    if (this.email) {
      await setDoc(
        doc(db, "newsletter_recipients", HelperFunctions.generateNewId()),
        {
          email: this.email,
          created: new Date(),
        }
      );
      this.$store.commit("SET_ALERT", {
        title: "Newsletter sign up",
        alert: "You have successfully signed up to our newsletter",
        type: "success",
      });
      setTimeout(() => {
        this.email = "";
      }, 3000);

      return "You have successfully signed up to our newsletter";
    }
  }
}
