import store from "../store";
import {
  db,
  setDoc,
  doc,
  collection,
  where,
  query,
  getDocs,
  updateDoc,
  deleteDoc,
  increment,
} from "../main";
import { IItem } from "@/interface/interface";

export default class ItemService {
  async uploadItem(item: IItem) {
    // const itemRef = doc(db, `items_${process.env.VUE_APP_ENV}`, item.id);
    // await setDoc(itemRef, item, { merge: true });
    await setDoc(doc(db, `items_${process.env.VUE_APP_ENV}`, item.id), {
      ...item,
    });
  }

  async getAllItems() {
    const itemRef = collection(db, `items_${process.env.VUE_APP_ENV}`);
    const querySnapshot = await getDocs(itemRef);
    // TODO: Use querySnapshot.size to show check if there are any items
    const items = querySnapshot.docs
      .map((doc) => doc.data())
      .map((item) => {
        return {
          ...item,
        };
      });
    store.commit("SET_ALL_ITEMS", items);
    return items;
  }

  async getUserItems(id: string) {
    const itemRef = collection(db, `items_${process.env.VUE_APP_ENV}`);
    const q = query(itemRef, where("owner", "==", id));
    const querySnapshot = await getDocs(q);
    if (querySnapshot.empty) {
      store.commit("SET_USER_ITEMS", []);
      return [];
    } else {
      const items = querySnapshot.docs.map((doc) => doc.data());
      store.commit("SET_USER_ITEMS", items);
      return items;
    }
  }

  async getItemNameById(id: string) {
    const itemRef = collection(db, `items_${process.env.VUE_APP_ENV}`);
    const q = query(itemRef, where("id", "==", id));
    const querySnapshot = await getDocs(q);
    const item = querySnapshot.docs.map((doc) => doc.data())[0];
    return item.name;
  }

  async getItemByUserId(uid: string) {
    const itemRef = collection(db, `items_${process.env.VUE_APP_ENV}`);
    const q = query(itemRef, where("uid", "==", uid));
    const querySnapshot = await getDocs(q);
    const item = querySnapshot.docs.map((doc) => doc.data())[0];
    store.commit("SET_ITEM", item);
    return item;
  }

  async getItemById(id: string) {
    const itemRef = collection(db, `items_${process.env.VUE_APP_ENV}`);
    const q = query(itemRef, where("id", "==", id));
    const querySnapshot = await getDocs(q);
    const item = querySnapshot.docs.map((doc) => doc.data())[0];
    store.commit("SET_ITEM", item);
    return item;
  }

  async updateItem(item: any) {
    store.commit("SET_ITEM", item);
    const itemRef = doc(db, `items_${process.env.VUE_APP_ENV}`, item.id);
    await updateDoc(itemRef, item);
    const itemsRef = collection(db, `items_${process.env.VUE_APP_ENV}`);
    const querySnapshot = await getDocs(itemsRef);
    const items = querySnapshot.docs.map((doc) => doc.data());
    store.commit("SET_ALL_ITEMS", items);
    return items;
  }

  async deleteItemsByUserId(userId: string) {
    const itemsRef = collection(db, "items");
    const q = query(itemsRef, where("owner", "==", userId));
    const querySnapshot = await getDocs(q);

    querySnapshot.forEach(async (doc) => {
      await deleteDoc(doc.ref);
      console.log(`Document in items deleted with ID: ${doc.id}`);
    });
    this.getAllItems();
  }

  async mostViewedItems() {
    const viewedItemsRef = collection(db, "viewed_items");
    const querySnapshot = await getDocs(viewedItemsRef);
    const items = querySnapshot.docs
      .map((doc) => doc.data())
      .map((item) => {
        return {
          ...item,
        };
      })
      .sort((a, b) => b.views - a.views)
      .slice(0, 3);
    return items;
  }

  async incrementViewedItem(item: any) {
    const itemRef = doc(db, "viewed_items", item.id);
    // Use setDoc with merge: true to create or update the document
    await setDoc(
      itemRef,
      {
        id: item.id,
        name: item.name,
        views: increment(1),
      },
      { merge: true }
    );
  }

  async reportItem(reportedItem: any) {
    // TODO: Check if has report item been added?
    const itemRef = doc(db, "reportedItems", reportedItem.id);
    await setDoc(itemRef, reportedItem, { merge: true });
  }

  async deleteItem(id: string) {
    console.log(id, "=====>");
    const itemRef = doc(db, `items_${process.env.VUE_APP_ENV}`, id);
    await deleteDoc(itemRef);
  }
}
