
import { Vue, Component } from "vue-property-decorator";

@Component({})
export default class BackToTopComponent extends Vue {
  showBackToTop = false;

  scrollToTop() {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Use smooth scrolling
    });
  }

  handleScroll() {
    // Check the scroll position and update showBackToTop accordingly
    this.showBackToTop = window.scrollY > 0;
  }

  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  }

  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll);
  }
}
